import React from 'react'
import { Helmet } from "react-helmet";
import PastParticipantedMarketing from '../Components/PastParticipatedMareketing';
import MarketingGallery from '../Components/MarketingGallery';
import {BannerDataMark} from "../Components/data.js";
import BannerSection from "../Components/BannerSection";
import {MarketingGData} from "../Components/data.js";
import Eleveting  from '../Components/Eleveting';
import {MarketingConfAbout} from "../Components/data.js";
import AboutAllConf  from '../Components/AboutAllConf';

export const Marketing = () => {
  return (
    <>
<Helmet>
<meta charset="utf-8" />
<title>2.0 Conferences At The Forefront Of Marketing Revolution</title>
<base href="/" />
<meta name="description" content=" Learn how Marketing 2.0 Conference is a crucial entity of the 2.0 Conferences – leading the marketing sector to modern innovation. " />
<meta property="og:title" content="2.0 Conferences At The Forefront Of Marketing Revolution" />

</Helmet>

      {/* ====================== */}
      <BannerSection bannerContent={BannerDataMark}/>
      {/* ======================= */}
      <Eleveting SpeakAbout={MarketingGData}/>
      {/* =================== */}
      <AboutAllConf ConfAbout={MarketingConfAbout}/>
       {/* =================== */}
       
      <PastParticipantedMarketing/>
      <MarketingGallery/>
{/* =================== */}
    </>
  )
}



export default Marketing;