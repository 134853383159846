import React from "react";
import ReviewSlider from "../Components/ReviewSlider";
import HomeSlider from "../Components/HomeSlider";
import StripSlider from "../Components/StripSlider"
import Video from "../Components/Video";
import CardData from "../Components/data"
import Card  from "../Components/Card"
import { Helmet } from "react-helmet";
const Home = () => {
  return (
    <>
    <Helmet>
<meta charset="utf-8" />
<title>The 2.0 Conferences</title>
<base href="/" />
<meta name="description" content="The 2.0 Conferences unite the changemakers from marketing, health, tech, education, and business sectors under one roof. Coming to the USA and Dubai in 2024."></meta>
<meta property="og:title" content="The 2.0 Conferences" />

</Helmet>
    {/* ========home_banner========== */}
      <section className="home_banner">
        <figure className="home_figure">
          
          <Video/>
          <figcaption className="home_figure_caption">
            <div className="container text-center">
              <h1>
                <p> Welcome To</p>
                The 2.0 Conferences
              </h1>
              <p>
              With a legacy of organizing and managing reputed industry events, <br/>we are committed to offering unparalleled opportunities for learning and networking. 
             
              </p>
            </div>
          </figcaption>
        </figure>
      </section>
      <StripSlider />
    {/*======global_events==========*/}
      <section className="global_events">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="globel_events_content">
                <h2>
                  <span>5 Global Events</span>
                  Infinite Epic Experiences
                </h2>
                <p>
                 <span>
                 Uniting Thought Leaders Who Are Shaping The Future Of Critical
                  Industries
                 </span>
                </p>
                <div className="about_events">
                  <p>
                  Our conference series brings together forward-thinkers leaders, the go-getters, the trailblazers, and the rule-breakers — because who said changing the game is a solo act? Through each, we seek to inspire and shape tomorrow by showcasing the most innovative ideas and strategies across a range of industries. 
                  </p>
                  <p>
                  At the heart of our mission is the goal of empowering individuals and organizations to think outside of the box, challenge norms, and explore new possibilities. By providing access to world-class speakers and experts, we help our attendees stay ahead of the curve. Our events are more than just a series of lectures; they are opportunities for them to connect, get recognized, share their experiences, and develop professional relationships that last a lifetime. 
                  </p>
                  <p>
                  We invite you to join us on this journey, where innovation meets opportunities, and interactions bring next-level growth. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =========events_hosted============== */}
      <section className="events_hosted">
        <div className="container">
          <h3>
            <span>Events</span> Hosted By Us
          </h3>
          <h4>
          At The 2.0 Conferences, we're all about industry synergy to drive innovation. Our focus spans industries, promoting cross-sector collaboration that yields transformative outcomes.
          </h4>
          <div className="events_logos">
            <div className="row">
               {CardData.map(Card) }
            </div>
          </div>
        </div>
      </section>
      {/* ========center_stage=========== */}
      <section className="center_stage">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="center_stage_heading">
                <h3>
                  Where Fresh Ideas Take
                  <span> Center Stage</span>
                </h3>
                <p>
                The 2.0 Conferences celebrates the convergence of brilliant minds with a common goal – to push the limits of what's possible. This is the place where industry-defining ideas collide with endless business opportunities.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="mt-5 col-sm-12 col-md-12 col-lg-4">
              <div className="center_boxs">
                <img src="https://s3.amazonaws.com/health-conference/the2conf/website-logo/men1.png" alt="icon" />
                <h5>
                  Thought-Provoking
                  <span>Sessions</span>
                </h5>
                <p>
                You can gain fresh perspectives about the trends and challenges of the sector with carefully-curated sessions, including panel discussions, keynote sessions, fireside chats, and many more Q&A sessions at the events. So, get ready to experience learning opportunities like never before!
                </p>
              </div>
            </div>
            <div className="mt-5 col-sm-12 col-md-12 col-lg-4">
              <div className="center_boxs">
                <img src="https://s3.amazonaws.com/health-conference/the2conf/website-logo/center_icon2.png" alt="icon" />
                <h5>
                Incredible Thought 
                  <span>Leaders</span>
                </h5>
                <p>
                As the conferences host incredible leaders from all over the world, you can meet trendsetters, connect with interested investors, and explore collaboration prospects with exceptional thought leaders, all under one roof and within 3 days.     
                </p>
              </div>
            </div>
            <div className="mt-5 col-sm-12 col-md-12 col-lg-4">
              <div className="center_boxs">
                <img src="https://s3.amazonaws.com/health-conference/the2conf/website-logo/center_icon3.png" alt="icon" />
                <h5>
                  Exclusive Branding 
                  <span>Opportunities</span>
                </h5>
                <p>
                Boost your brand reputation and awareness by exhibiting or sponsoring the events organized by The 2.0 Conferences team. Feel free to reach out to the team to learn more about the advantages and benefits of sponsoring or exhibiting your ideas and solutions on such a huge platform before the world's top-notch leaders. 

                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==========our_journey================ */}
      <section className="our_journey">
        <div className="container">
          <div className="jaurney_heading">
            <h3>
               our <span>journey</span>{" "}
              
            </h3>
            <p>
            We've been right there alongside hundreds of brands, helping them shine in their industries, navigate today's challenges, and gear up for what's coming next. Our passion for shaking up how events happen shines through in our conferences—real conversations and real solutions for the ever-evolving world.

            </p>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 colg">
              <div className="jaurney_icons">
                <div className="jaurney_icons_img">
                  <img src="https://s3.amazonaws.com/health-conference/the2conf/icons/event.png" alt="event" />
                </div>
                <h4>
                  <strong>5+ Years</strong> In The Events Space
                </h4>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 colg">
              <div className="jaurney_icons">
                <div className="jaurney_icons_img">
                  <img src="https://s3.amazonaws.com/health-conference/the2conf/icons/thought-providing.png" alt="thought-providing" />
                </div>
                <h4>
                  <strong>68  Conferences</strong> Since 2018
                </h4>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3  colg">
              <div className="jaurney_icons">
                <div className="jaurney_icons_img">
                  <img src="https://s3.amazonaws.com/health-conference/the2conf/icons/crowed.png" alt="crowed" />
                </div>
                <h4>
                  <strong>10,000+</strong> Attendees Till Date
                </h4>
              </div>
            </div>
            <div className="col-12 col-sm-6  col-md-6 col-lg-2 colg">
              <div className="jaurney_icons">
                <div className="jaurney_icons_img">
                  <img src="https://s3.amazonaws.com/health-conference/the2conf/icons/org.png" alt="org" />
                </div>
                <h4>
                  <strong>600+</strong> Organizations
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ================== */}
      <section className="attendees_sec">
        <div className="container">
          <h3>
            Hear It From Our <span> Past Attendees</span>{" "}
          </h3>

          <h4>
          Get a glimpse of the conference experience through the eyes of our past attendees. Here are their firsthand accounts of how our events have made a difference in their professional journeys.
       
           
          </h4>
         
        </div>
        <ReviewSlider />
      </section>
      {/* ============== */}
      <section className="confrence_community text-center">
        <div className="container">
          <h3>our <span> Community </span></h3>
          <h4>
          Representatives and employees of some of the world’s most renowned organizations have attended our previous events. 
          </h4>
        </div>
      </section>
      {/* ============ */}
      <HomeSlider />
      
      {/*==========from_section===========*/}
      {/* <section className="our_journey from_section">
            <div className="container">
               
                        <div className="jaurney_heading from_heading">
                            <h3>
                                  Want To Explore More?
                                <span> Let’s Talk.</span>

                            </h3>
                            <p>
                               We'd love to connect and share how our conferences can elevate your industry presence. Just take a moment to fill out this form, and we'll be in touch!
                            </p>
                        </div>
                      
               
            </div>
        </section> */}
    </>
  );
};

export default Home;
