import React from 'react'

const Card = (props) => {
  return (
    <>

<div className="col">
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip_card_front">
                      <div className="card">
                        <p>{props.eventName}</p>
                        <div className="logo_website">
                          <img
                            src={props.eventImg}
                            className="card-img-top"
                            alt="education"
                            width="100%"
                          />
                        </div>
                        <div className="card-body">
                          <p className="card-text">
                           {props.evDate} | <br /> {props.eventText}
                          </p>
                          <div className="card_vist">
                            <p>visit us!</p>
                            <a href={props.hostedurl} rel="noopener noreferrer">→</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flip_card_back">
                      <div className="card">
                        <div className="card-body">
                          <p className="card-text">
                          {props.cardText}
                          </p>
                          <div className="card_vist">
                            <p>visit us!</p>
                            <a href={props.hostedurl} target="_blank" rel="noopener noreferrer">→</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

    </>
  )
}

export default Card