import React from 'react'

const Gallary = (props) => {

  return (
   <>
      <section className="gallery_wrappering">
       <div className="container">
       <div className='gallery_content'>
         <div className="row">
         <div className="col-12 col-sm-12 col-md-12 col-lg-8 padding">
            <div className="heading_wrappping">
               <h4 className="headingg">{props.GallaryTitle} </h4>
               <p className="paragragh_heading">{props.GallaryContent} </p>
               <p className="view_more"><a href={props.GallaryUrl} target="_blank" rel="noopener noreferrer">View Gallery Page </a></p>
            </div>
         </div>
         <div className="col-12 col-sm-12 col-md-12 col-lg-4 padding"> 
            <img className="gallery_image"  src={props.GallaryImg} width="100%"  alt={props.GallaryImgAlt}/>
         </div>
      </div>
   </div>
      
   </div>
   </section>
   </>
  )
}

export default Gallary