import React from 'react';


const logoList = [
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Marketing-image/logo1.png", alt: "Logo 1" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Marketing-image/logo2.png", alt: "Logo 2" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Marketing-image/logo3.png", alt: "Logo 3" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Marketing-image/logo4.png", alt: "Logo 4" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Marketing-image/logo5.png", alt: "Logo 5" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Marketing-image/logo6.png", alt: "Logo 6" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Marketing-image/logo7.png", alt: "Logo 7" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Marketing-image/logo8.png", alt: "Logo 8" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Marketing-image/logo9.png", alt: "Logo 9" }
];

const PastParticipantedMarketing = () => {
  return (
    <>
      <section className="attendees_sec past_participants">
        <div className="container">
          <h3>
            <span>Our Previous Attendees Have Been From…</span>
          </h3>
          <h4>
            Step into the world of experts! Get ready to rub shoulders with the trailblazing innovators
            <br />
            from well-known organizations who are revolutionizing the industry.
          </h4>
        </div>
      </section>

      <section className='healthcare_logos_section'>
        <div className='container'>
          <div className='row justify-content-center'>
            {logoList.map((logo, index) => (
              <div key={index} className='col-6 col-sm-6 col-md-4 col-lg-2'>
                <div className='past_logos'>
                  <img src={logo.src} alt={logo.alt} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default PastParticipantedMarketing;
